<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-toolbar-title class="pr-8"
            >Batch Info ({{ database }})</v-toolbar-title
          >

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-text-field
            class="pl-10"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <v-progress-linear
            :active="loading"
            :indeterminate="true"
            absolute
            bottom
            color="blue-grey darken-3"
          ></v-progress-linear>
        </v-toolbar>

        <v-card>
          <v-data-table
            :headers="headers"
            :items="batches"
            :search="search"
            hide-default-footer
            disable-pagination
            dense
          >
            <template v-slot:[`item.rec_count`]="{ item }">
              {{ formatNumber(item.rec_count) }}
            </template>

            <template slot="body.append">
              <tr>
                <td>Totals</td>
                <td></td>
                <td></td>
                <td class="text-center">{{ formatNumber(totalCount) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDateTime, formatNumber } from "@/lib/utils";

const REC_COUNT_COL = {
  text: "Rec Count",
  sortable: false,
  value: "rec_count",
  align: "center",
};
const TABLE_NAME_COL = {
  text: "Table Name",
  sortable: false,
  value: "table_name",
};
const MIN_DATE_COL = {
  text: "Min Date",
  sortable: false,
  value: "min_date",
};
const MAX_DATE_COL = {
  text: "Max Date",
  sortable: false,
  value: "max_date",
};

export default {
  components: {},

  props: {
    database: {
      type: String,
      required: true,
    },

    displayHeaders: {
      type: [Array, String],
      default: () => [],
    },
  },

  data() {
    return {
      search: "",
      headers: [TABLE_NAME_COL, MIN_DATE_COL, MAX_DATE_COL, REC_COUNT_COL],
    };
  },

  created() {
    this.$eventHub.$on("remove-batch", this.removeBatch);
  },

  beforeDestroy() {
    this.$eventHub.$off("remove-batch");
  },

  computed: {
    batches() {
      let moduleName = this.getModuleName();
      let result = this.$store.getters[`${moduleName}/getBatches`];
      return result ? result : [];
    },

    loading() {
      let moduleName = this.getModuleName();
      return this.$store.getters[`${moduleName}/isBatchInfoLoading`];
    },

    totalCount() {
      let batch = this.batches;
      const x = batch.reduce((acc, item) => {
        return acc + item.rec_count;
      }, 0);
      return x;
    },
  },

  methods: {
    formatNumber(value) {
      // stupid, but here so the linter doesn't complain
      return formatNumber(value);
    },

    formatDT(value) {
      // stupid, but here so the linter doesn't complain
      return formatDateTime(value);
    },

    removeBatch(batchID) {
      let modName = this.getModuleName();
      this.$store.dispatch(`${modName}/removeBatch`, {
        import_batch_id: batchID,
      });
    },

    getModuleName() {
      let moduleName = "";

      switch (this.database) {
        case "ETL-1":
          moduleName = "etl_1";
          break;
        case "ETL-2":
          moduleName = "etl_2";
          break;
        case "DB-1":
          moduleName = "db_1";
          break;
        case "FSDB-1":
          moduleName = "fsdb_1";
          break;
        case "SPDB-1":
          moduleName = "spdb_1";
          break;
        case "RETAIL-1":
          moduleName = "retail_1";
          break;
      }

      return moduleName;
    },
  },
};
</script>

<style></style>
